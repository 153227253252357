import '@/styles/globals.css';

import { ApolloProvider } from '@apollo/client';
import type { AppProps } from 'next/app';
import Head from 'next/head';

import { useApollo, useArticleApollo } from '@/api/apolloClient';
import { CookieBar } from '@/components/cookies/CookieBar';
import { CookieSetting } from '@/components/cookies/CookieSetting';
import { AnalyticsProvider } from '@/components/providers/AnalyticsProvider';

const App = ({ Component, pageProps }: AppProps) => {
  const client = useApollo(pageProps);
  const articleClient = useArticleApollo(pageProps);

  return (
    <ApolloProvider client={articleClient}>
      <ApolloProvider client={client}>
        <Head>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />
          <title>Collapo</title>
        </Head>
        <AnalyticsProvider>
          <Component {...pageProps} />
          <CookieBar key="cookies-bar" />
          <CookieSetting key="cookies-settings" />
        </AnalyticsProvider>
      </ApolloProvider>
    </ApolloProvider>
  );
};

export default App;
