import {
  ContentFragment,
  FooterQuery,
  FormContactWebexQuery,
  ImageFragment,
  NavigationQuery,
  SeoFragment,
} from '@/api';
import { FormContact } from '@/components/form/contactForm/FormContact';
import { Layout } from '@/components/layout/Layout';
import PictureWithBlurHash from '@/components/PictureWithBlurHash/PictureWithBlurHash';
import { Seo } from '@/components/Seo';

type Props = {
  seo?: SeoFragment | null;
  image?: ImageFragment | null;
  content?: ContentFragment | null;
  formWebexData: FormContactWebexQuery;
  footerData: FooterQuery;
  headerData: NavigationQuery;
};

export const Homepage = ({
  seo,
  image,
  content,
  formWebexData,
  footerData,
  headerData,
}: Props) => {
  return (
    <Layout footerData={footerData} headerData={headerData}>
      <Seo seo={seo} />
      <h2>{content?.title}</h2>
      <div>{content?.content}</div>

      {image && (
        <section>
          <div className="bg-tg-bg py-10 lg:py-[60px]">
            <div className="group relative h-[400px] w-full">
              <PictureWithBlurHash
                fullScreen
                alt={image.alt}
                imgSrc={image.img_desktop.data?.attributes?.url || ''}
                blurHash={
                  image.img_desktop?.data?.attributes?.blurhash ||
                  'L35rfH^$xZS%Q*t:tni^M{Zyxwxv'
                }
                classNameWrapper="h-[370px] w-full lg:h-[451px]"
                classNameImage="transition-all duration-300 group-hover:scale-105"
              />
            </div>
          </div>
        </section>
      )}
      {formWebexData && <FormContact data={formWebexData} />}
    </Layout>
  );
};
