import { getCookie, hasCookie, setCookie } from 'cookies-next';
import { useEffect, useState } from 'react';

import { useCookiesBarQuery } from '@/api';
import { cookieExpirationDate } from '@/constants/cookies';

export const CookieBar = () => {
  const [visible, setVisible] = useState(false);
  const { data } = useCookiesBarQuery();
  const cookiesBarData = data?.cookiesBanner?.data?.attributes;

  useEffect(() => {
    hasCookie('rtech_cookie_consent_necessary') &&
    getCookie('rtech_cookie_consent_necessary') === 'true'
      ? setVisible(false)
      : setVisible(true);
  }, []);

  const declineOptional = () => {
    setCookie('rtech_cookie_consent_necessary', true, {
      expires: cookieExpirationDate,
    });
    setVisible(false);
  };

  const setAll = () => {
    setCookie('rtech_cookie_consent_necessary', true, {
      expires: cookieExpirationDate,
    });
    setCookie('rtech_cookie_consent_optional', true, {
      expires: cookieExpirationDate,
    });
    setVisible(false);
  };

  // const cookieBarProps: CookiesBarProps = {
  //   heading: cookiesBarData?.heading || '',
  //   text: cookiesBarData?.text,
  //   editButton: {
  //     label: cookiesBarData?.editButtonLabel || '',
  //     path: '#cookiesSettings',
  //     useCase: 'form',
  //   },
  //   declineButton: {
  //     label: cookiesBarData?.declineButtonLabel || '',
  //     onClick: declineOptional,
  //     useCase: 'form',
  //   },
  //   acceptButton: {
  //     label: cookiesBarData?.acceptButtonLabel || '',
  //     onClick: setAll,
  //     useCase: 'technology',
  //   },
  //   extraLinks: (cookiesBarData?.extraLinks || []).map((link) => ({
  //     isTextLink: true,
  //     label: link?.label || '',
  //     path: link?.path || '',
  //   })),
  // };

  if (visible && data)
    return (
      <div className="fixed bottom-none left-1/2 z-10 w-full -translate-x-1/2">
        {/*<CookiesBar {...cookieBarProps} />*/}
        <p>CookiesBar</p>
      </div>
    );
};
