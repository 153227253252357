interface DropdownItemProps {
  dropdownLink: string;
  dropdownText: string;
}

const DropdownItem = ({ dropdownLink, dropdownText }: DropdownItemProps) => {
  return (
    <a
      href={dropdownLink}
      className="rounded text-sm block px-4 py-[10px] font-medium text-body-color hover:bg-primary hover:text-white"
    >
      {dropdownText}
    </a>
  );
};

export default DropdownItem;
