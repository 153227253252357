import {
  Children,
  cloneElement,
  Dispatch,
  isValidElement,
  ReactNode,
  SetStateAction,
  useState,
} from 'react';

interface ListItemProps {
  children: ReactNode;
}

interface ChildProps {
  subMenu: boolean;
  setSubMenu: Dispatch<SetStateAction<boolean>>;
}

const ListItem = ({ children }: ListItemProps) => {
  const [subMenu, setSubMenu] = useState(true);

  const childWithProps = Children.map(children, (child) => {
    if (isValidElement<ChildProps>(child)) {
      return cloneElement(child, {
        subMenu,
        setSubMenu,
      });
    }
    return child;
  });

  return (
    <li className="submenu-item group relative lg:ml-12">{childWithProps}</li>
  );
};

export default ListItem;
