import clsx from 'clsx';
import { CldImage } from 'next-cloudinary';
import { useInView } from 'react-intersection-observer';

import { useColumnSizes } from '@/hooks/useColumnSizes';

import { PictureProps } from './Picture.types';

const Picture = ({
  imgSrc,
  alt,
  classNameImage,
  classNameWrapper,
  classNameInnerWrapper,
  enabledLazyLoading = true,
  fullScreen = false,
  width = 0,
  height = 0,
  threshold = 0,
  sizes = 'oneColumn',
  format,
}: PictureProps) => {
  const inViewOptions = {
    threshold: threshold,
    triggerOnce: true,
  };

  const [ref, inView] = useInView(inViewOptions);
  const columnSizes = useColumnSizes(sizes);

  return (
    <div
      className={clsx(
        'overflow-hidden',
        fullScreen && 'relative',
        classNameWrapper,
      )}
    >
      <div
        className={clsx(
          'transition-opacity duration-0 motion-reduce:transition-none',
          enabledLazyLoading
            ? inView
              ? 'opacity-100'
              : 'opacity-0'
            : 'opacity-100',
          classNameInnerWrapper,
        )}
      >
        <CldImage
          ref={ref}
          src={imgSrc}
          alt={alt}
          format={format}
          className={clsx(
            'duration-300',
            enabledLazyLoading &&
              'transition-all motion-reduce:transition-none',
            fullScreen ? 'w-full object-cover' : 'object-contain',
            classNameImage,
          )}
          width={width}
          height={height}
          sizes={columnSizes}
          fill={fullScreen}
          loading={enabledLazyLoading ? 'lazy' : 'eager'}
        />
      </div>
    </div>
  );
};

export default Picture;
