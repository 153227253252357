import clsx from 'clsx';
import { Blurhash } from 'react-blurhash';

import { BlurHashImageProps } from './HashBlurImage.types';

const HashBlurImage = ({
  blurHash = 'LABWiA-;00D%~qx]4nof00D%~q?I',
  children,
  className,
  classNameSize,
  enabledDuration = false,
}: BlurHashImageProps) => {
  return (
    <div
      className={clsx(
        'absolute inset-none z-[1] block transition-all motion-reduce:transition-none',
        enabledDuration ? 'duration-700' : 'duration-0',
        className,
      )}
    >
      {children}
      <Blurhash
        hash={blurHash}
        punch={1}
        className={clsx(
          '!absolute !inset-none z-[1] !block',
          classNameSize ? `${classNameSize} !h-auto !w-full` : '!size-full',
        )}
      />
    </div>
  );
};

export default HashBlurImage;
