interface ProjectConfig {
  cloudinaryProjectName: string;
  projectVideoCompanyLink: string;
}

const projectConfig: ProjectConfig = {
  cloudinaryProjectName: 'collapo/static',
  projectVideoCompanyLink: '/kontakt',
};

const projectVideoLogoPublicId = `${projectConfig.cloudinaryProjectName}/logo-video`;

export { projectConfig, projectVideoLogoPublicId };
