import * as yup from 'yup';

export const FormSchemaContact = yup.object({
  email: yup
    .string()
    .email('Neplatný email')
    .trim()
    .required('Toto pole je povinné'),
  fullName: yup
    .string()
    .trim()
    .required('Toto pole je povinné')
    .test(
      'is-full-name',
      'Uveďte prosím celé jméno (jméno a příjmení)',
      (value) => value?.trim().split(' ').length > 1,
    ),
  companyName: yup.string().trim().required('Toto pole je povinné'),
  message: yup.string(),
  terms: yup
    .bool()
    .oneOf([true], 'Pole musí být zaškrtnuto')
    .required('Toto pole je povinné'),
});

export type FormSchemaContactType = yup.InferType<typeof FormSchemaContact>;

export const FormSchemaService = yup.object({
  email: yup
    .string()
    .email('Neplatný email')
    .trim()
    .required('Toto pole je povinné'),
  fullName: yup
    .string()
    .trim()
    .required('Toto pole je povinné')
    .test(
      'is-full-name',
      'Uveďte prosím celé jméno (jméno a příjmení)',
      (value) => value?.trim().split(' ').length > 1,
    ),
  companyName: yup.string().trim().required('Toto pole je povinné'),
  issueSubject: yup.string().required('Toto pole je povinné'),
  issueDescription: yup.string().required('Toto pole je povinné'),
  terms: yup
    .bool()
    .oneOf([true], 'Pole musí být zaškrtnuto')
    .required('Toto pole je povinné'),
});

export type FormSchemaServiceType = yup.InferType<typeof FormSchemaService>;
