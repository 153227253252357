import { useRouter } from 'next/router';

import { NavigationQuery } from '@/api';
import Header from '@/components/header/Header';

interface NavigationProps {
  data: NavigationQuery;
}

export const Navigation = ({ data }: NavigationProps) => {
  const navData = data?.navigation?.data?.attributes;
  const { pathname } = useRouter();
  // if (!navData) return null;

  // const headerProps: HeaderProps = {
  //   useCase: 'technology' as useCaseType,
  //   menuColsOpened: (navData?.openMenuColumns || []).map((column) => ({
  //     label: column?.label || '',
  //     menuLinks: (column?.link || []).map((link) => ({
  //       label: link?.label || '',
  //       path: link?.path || '',
  //       openInNewTab: !!link?.openInNewTab,
  //     })),
  //   })),
  //   menuLinksClosed: (navData.closedMenuLinks || []).map((link) => ({
  //     label: link?.label || '',
  //     path: link?.path || '',
  //     openInNewTab: !!link?.openInNewTab,
  //   })),
  //   activePath: pathname,
  // };

  return (
    <>
      <Header />
      {/*<Header {...headerProps} invertedColor={invertedColor} />*/}
    </>
  );
};
