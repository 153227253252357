import clsx from 'clsx';
import { CldImage } from 'next-cloudinary';
import { useState } from 'react';
import { useInView } from 'react-intersection-observer';

import { useColumnSizes } from '@/hooks/useColumnSizes';

import HashBlurImage from '../HashBlurImage/HashBlurImage';
import { PictureWithBlurHashProps } from './PictureWithBlurHash.types';

const PictureWithBlurHash = ({
  imgSrc,
  imgSrcMobile,
  alt,
  classNameWrapper,
  classNameImage,
  enabledLazyLoading = true,
  blurHash,
  fullScreen = false,
  width = 0,
  height = 0,
  widthMobile = 0,
  heightMobile = 0,
  isPathMobile = false,
  gravity,
  threshold = 0,
  sizes = 'highResolution',
  onClick,
}: PictureWithBlurHashProps) => {
  const [completeLoading, setCompleteLoading] = useState(false);

  const imageUrlDesktop = `${imgSrc}`;
  const imageUrlMobile = imgSrcMobile ? `${imgSrcMobile}` : `${imgSrc}`;

  const inViewOptions = {
    threshold: threshold,
    triggerOnce: true,
  };

  const [ref, inView] = useInView(inViewOptions);
  const [refMobile, inViewMobile] = useInView(inViewOptions);

  const handleCompleteLoading = () => {
    setCompleteLoading(true);
  };

  const columnSizes = useColumnSizes(sizes);

  return (
    <div
      className={clsx('relative overflow-hidden', classNameWrapper)}
      onClick={onClick}
    >
      <div
        className={clsx(
          'transition-opacity duration-0 motion-reduce:transition-none',
          enabledLazyLoading
            ? inView
              ? 'opacity-100'
              : 'opacity-0'
            : 'opacity-100',
        )}
      >
        <CldImage
          ref={ref}
          src={imageUrlDesktop}
          alt={alt}
          className={clsx(
            'transition-all duration-300 motion-reduce:transition-none',
            classNameImage,
            fullScreen ? 'w-full object-cover' : 'object-contain',
            isPathMobile && 'max-md:hidden',
          )}
          width={width}
          height={height}
          sizes={columnSizes}
          fill={fullScreen}
          loading={enabledLazyLoading ? 'lazy' : 'eager'}
          onLoad={() => {
            handleCompleteLoading();
          }}
          {...(gravity && { crop: 'lfill', gravity })}
        />
      </div>
      {isPathMobile && (
        <div
          className={clsx(
            'transition-opacity duration-0 motion-reduce:transition-none',
            enabledLazyLoading
              ? inViewMobile
                ? 'opacity-100'
                : 'opacity-0'
              : 'opacity-100',
          )}
        >
          <CldImage
            ref={refMobile}
            src={imageUrlMobile ? imageUrlMobile : imageUrlDesktop}
            alt={alt}
            className={clsx(
              'md:hidden',
              'transition-all duration-300 motion-reduce:transition-none',
              classNameImage,
              fullScreen ? 'w-full object-cover' : 'object-contain',
            )}
            width={widthMobile ? widthMobile : width}
            height={heightMobile ? heightMobile : height}
            sizes="100vw"
            fill={fullScreen}
            loading={enabledLazyLoading ? 'lazy' : 'eager'}
            onLoad={() => {
              handleCompleteLoading();
            }}
            {...(gravity && { crop: 'lfill', gravity })}
          />
        </div>
      )}
      <HashBlurImage
        blurHash={blurHash}
        className={clsx(
          classNameWrapper,
          completeLoading ? 'opacity-0' : 'opacity-100',
        )}
      />
    </div>
  );
};
export default PictureWithBlurHash;
