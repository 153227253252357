import { GetStaticPropsContext, InferGetStaticPropsType, NextPage } from 'next';

import {
  FooterDocument,
  FooterQuery,
  FooterQueryVariables,
  FormContactWebexDocument,
  FormContactWebexQuery,
  FormContactWebexQueryVariables,
  HomeContentDocument,
  HomeContentQuery,
  HomeContentQueryVariables,
  HomeImageDocument,
  HomeImageQuery,
  HomeImageQueryVariables,
  HomeSeoDocument,
  HomeSeoQuery,
  HomeSeoQueryVariables,
  NavigationDocument,
  NavigationQuery,
  NavigationQueryVariables,
} from '@/api';
import { addApolloState, initializeApollo } from '@/api/apolloClient';
import { Homepage } from '@/views/homepage';

export const getStaticProps = async (context: GetStaticPropsContext) => {
  const client = initializeApollo();
  const { locale } = context;

  // prefetch
  await client.query({ query: HomeSeoDocument });
  await client.query({ query: HomeImageDocument });
  await client.query({ query: HomeContentDocument });
  await client.query({ query: FooterDocument });
  await client.query({ query: NavigationDocument });
  await client.query({ query: FormContactWebexDocument });

  const { data: seoData } = await client.query<
    HomeSeoQuery,
    HomeSeoQueryVariables
  >({ query: HomeSeoDocument });

  const { data: imageData } = await client.query<
    HomeImageQuery,
    HomeImageQueryVariables
  >({ query: HomeImageDocument });

  const { data: contentData } = await client.query<
    HomeContentQuery,
    HomeContentQueryVariables
  >({ query: HomeContentDocument, variables: { locale: locale || 'cs' } });

  const { data: formData } = await client.query<
    FormContactWebexQuery,
    FormContactWebexQueryVariables
  >({
    query: FormContactWebexDocument,
  });

  const { data: footerData } = await client.query<
    FooterQuery,
    FooterQueryVariables
  >({ query: FooterDocument });

  const { data: headerData } = await client.query<
    NavigationQuery,
    NavigationQueryVariables
  >({ query: NavigationDocument });

  return addApolloState(client, {
    props: {
      seoData,
      imageData,
      contentData,
      formData,
      footerData,
      headerData,
    },
  });
};

const Home: NextPage<InferGetStaticPropsType<typeof getStaticProps>> = ({
  seoData,
  imageData,
  contentData,
  formData,
  footerData,
  headerData,
}) => (
  <Homepage
    seo={seoData?.home?.data?.attributes?.seo}
    image={imageData?.home?.data?.attributes?.image}
    content={contentData?.home?.data?.attributes?.content}
    formWebexData={formData}
    footerData={footerData}
    headerData={headerData}
  />
);

export default Home;
