import { getCookie, hasCookie, setCookie } from 'cookies-next';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import { useCookiesSettingsQuery } from '@/api';
import { cookieExpirationDate } from '@/constants/cookies';

export const CookieSetting = () => {
  const { data } = useCookiesSettingsQuery();
  const [visible, setVisible] = useState(false);
  const [optionalCookies, setOptional] = useState(
    hasCookie('rtech_cookie_consent_optional') &&
      getCookie('rtech_cookie_consent_optional') === 'true',
  );
  const settingsData = data?.cookieSetting?.data?.attributes;

  const onSave = () => {
    setCookie('rtech_cookie_consent_optional', optionalCookies, {
      expires: cookieExpirationDate,
    });
    setVisible(false);
  };

  const router = useRouter();

  useEffect(() => {
    if (window.location.hash && !visible) {
      const { pathname, search } = window.location;
      const newPath = pathname + search;
      router.replace(newPath, undefined, { shallow: true });
    }
  }, [router, visible]);

  useEffect(() => {
    const handleUrlChange = () => {
      if (window.location.hash.substring(1).includes('cookiesSettings')) {
        setVisible(true);
      } else {
        setVisible(false);
      }
    };

    window.addEventListener('hashchange', handleUrlChange);

    return () => {
      window.removeEventListener('hashchange', handleUrlChange);
    };
  }, []);

  // const modalData: CookiesSettingsProps = {
  //   heading: settingsData?.title || '',
  //   closeModal: () => setVisible(false),
  //   useCase: 'technology' as useCaseType,
  //   actionButton: {
  //     useCase: 'technology',
  //     label: settingsData?.actionButtonLabel || '',
  //     onClick: () => onSave(),
  //   },
  //   moreInfoLink: {
  //     label: settingsData?.redirectButton?.label || '',
  //     path: settingsData?.redirectButton?.path || '',
  //     useCase: 'technology' as useCaseType,
  //   },
  //   isModalOpen: visible,
  //   tableContentItems: [
  //     {
  //       heading: settingsData?.necessaryCookiesHeading || '',
  //       toggleSwitch: {
  //         initialValue: true,
  //         isFixed: true,
  //         onChange: () => {},
  //         useCase: 'technology' as useCaseType,
  //       },
  //       tableContent: {
  //         useCase: 'technology',
  //         text: settingsData?.necessaryCookiesTable?.richText || '',
  //         tableData: {
  //           headings: (
  //             settingsData?.necessaryCookiesTable?.tableHead || []
  //           ).map((item) => item?.headingItem || ''),
  //           values: (
  //             settingsData?.necessaryCookiesTable?.tableRow?.table_rows?.data ||
  //             []
  //           ).map((row) =>
  //             (row.attributes?.rows || []).map((item) => item?.item || ''),
  //           ),
  //         },
  //       },
  //     },
  //     {
  //       heading: settingsData?.optionalCookiesHeading || '',
  //       toggleSwitch: {
  //         initialValue: optionalCookies,
  //         isFixed: false,
  //         onChange: () => setOptional(!optionalCookies),
  //         useCase: 'technology' as useCaseType,
  //       },
  //       tableContent: {
  //         useCase: 'technology',
  //         text: settingsData?.optionalCookiesTale?.richText || '',
  //         tableData: {
  //           headings: (settingsData?.optionalCookiesTale?.tableHead || []).map(
  //             (item) => item?.headingItem || '',
  //           ),
  //           values: (
  //             settingsData?.optionalCookiesTale?.tableRow?.table_rows?.data ||
  //             []
  //           ).map((row) =>
  //             (row.attributes?.rows || []).map((item) => item?.item || ''),
  //           ),
  //         },
  //       },
  //     },
  //   ],
  // };

  if (visible && data)
    return (
      <>
        <p>CookiesSettings</p>
        {/*<CookiesSettings {...modalData} />*/}
      </>
    );
};
