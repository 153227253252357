import { Dispatch, MouseEvent, ReactNode, SetStateAction } from 'react';

interface LinkItemProps {
  children: ReactNode;
  navLink: string;
  setSubMenu?: Dispatch<SetStateAction<boolean>>;
  dropdown?: boolean;
  subMenu?: boolean;
}

const LinkItem = ({
  children,
  navLink,
  subMenu = false,
  setSubMenu = () => {},
  dropdown = false,
}: LinkItemProps) => {
  const handleClick = (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    setSubMenu(!subMenu);
  };

  return (
    <a
      href={navLink}
      onClick={handleClick}
      className={`text-base relative flex px-6 py-2 font-medium text-body-color group-hover:text-dark lg:inline-flex lg:py-6  lg:pl-0 lg:pr-4 ${
        dropdown &&
        'after:absolute after:right-5 after:top-1/2 after:mt-[-2px] after:size-2 after:translate-y-[-50%] after:rotate-45 after:border-b-2 after:border-r-2 after:border-current lg:after:right-0'
      }`}
    >
      {children}
    </a>
  );
};

export default LinkItem;
