import { ReactNode } from 'react';

interface DropdownProps {
  children: ReactNode;
  subMenu?: boolean;
}

const Dropdown = ({ children, subMenu = false }: DropdownProps) => {
  return (
    <div
      className={`rounded-lg relative left-0 top-full bg-white px-4 transition-all group-hover:opacity-100 lg:invisible lg:absolute lg:top-[115%] lg:w-[250px] lg:p-4 lg:opacity-0 lg:shadow-lg lg:group-hover:visible lg:group-hover:top-full 
      ${subMenu ? 'hidden lg:block' : 'block'}`}
    >
      {children}
    </div>
  );
};

export default Dropdown;
