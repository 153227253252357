import { useEffect } from 'react';

export const useDisableScroll = (displayModal: boolean) => {
  useEffect(() => {
    const handleWheel = (e: WheelEvent | TouchEvent | Event) => {
      if (displayModal) {
        e.preventDefault();
      }
    };

    if (typeof document !== 'undefined') {
      document.addEventListener('wheel', handleWheel, { passive: false });
      document.addEventListener('scroll', handleWheel, { passive: false });
      document.addEventListener('touchmove', handleWheel, { passive: false });

      return () => {
        document.removeEventListener('wheel', handleWheel);
        document.removeEventListener('scroll', handleWheel);
        document.removeEventListener('touchmove', handleWheel);
      };
    }
  }, [displayModal]);
};
