import { useEffect } from 'react';

interface Props {
  handleClose: () => void;
}

export const useCloseByEsc = ({ handleClose }: Props) => {
  useEffect(() => {
    // Only add event listener if `document` is defined
    if (typeof document === 'undefined') return;

    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        handleClose();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleClose]);
};
