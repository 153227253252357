import { FooterQuery } from '@/api';

export const Footer = ({ data }: { data: FooterQuery }) => {
  const footerData = data?.footer?.data?.attributes;

  const columns = (footerData?.column || []).map((column) => ({
    label: column?.label || '',
    links: (column?.link || []).map((link) => ({
      label: link?.label || '',
      path: link?.file?.data?.attributes?.url || link?.path || '',
      openInNewTab:
        !!link?.file?.data?.attributes?.url || link?.openInNewTab || undefined,
      download: !!link?.file?.data?.attributes?.url,
    })),
  }));

  const policies = {
    label: footerData?.policies?.label || '',
    links: (footerData?.policies?.link || []).map((link) => ({
      path: link?.path || '',
      label: link?.label || '',
    })),
  };

  const contact = {
    title: footerData?.contact?.title || '',
    eMail: {
      label: footerData?.contact?.email?.label || '',
      path: footerData?.contact?.email?.path || '',
    },
  };

  return (
    <>
      <p>Footer</p>
      {/*<FooterComponent*/}
      {/*  columns={columns}*/}
      {/*  socials={socials}*/}
      {/*  policies={policies}*/}
      {/*  contact={contact}*/}
      {/*  useCase={'technology' as useCaseType}*/}
      {/*/>*/}
    </>
  );
};
