import { useEffect, useState } from 'react';

import { pictureSizeType } from '@/types/pictureSizeType';

export function useColumnSizes(sizes: pictureSizeType) {
  const [columnSizes, setColumnSizes] = useState('100vw');

  useEffect(() => {
    if (sizes === 'oneColumn') {
      setColumnSizes('100vw');
    } else if (sizes === 'twoColumns') {
      setColumnSizes('(max-width: 1200px) 100vw, 50vw');
    } else if (sizes === 'threeColumns') {
      setColumnSizes(
        '(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw',
      );
    } else if (sizes === 'fourColumns') {
      setColumnSizes(
        '(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 25vw',
      );
    } else {
      setColumnSizes(
        '(max-width: 768px) 500vw, (max-width: 1200px) 200vw, 100vw',
      );
    }
  }, [sizes]);

  return columnSizes;
}
