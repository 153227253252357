export const SuccessIcon = () => (
  <svg
    width="59"
    height="58"
    viewBox="0 0 59 58"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.489258 -0.0117188H1.73926H16.6684V2.48828H2.98926V16.1674H0.489258V1.23828V-0.0117188ZM42.3316 -0.0117188H57.2607H58.5107V1.23828V16.1674H56.0107V2.48828H42.3316V-0.0117188ZM0.489258 56.7597V41.8306H2.98926V55.5097H16.6684V58.0097H1.73926H0.489258V56.7597ZM58.5107 41.8306V56.7597V58.0097H57.2607H42.3316V55.5097H56.0107V41.8306H58.5107ZM9.37305 8.87109H10.623H25.3054V11.3711H11.873V24.8034H9.37305V10.1211V8.87109ZM33.6953 8.87109H48.3776H49.6276V10.1211V24.8034H47.1276V11.3711H33.6953V8.87109ZM9.37305 47.8757V33.1933H11.873V46.6257H25.3054V49.1257H10.623H9.37305V47.8757ZM49.6276 33.1933V47.8757V49.1257H48.3776H33.6953V46.6257H47.1276V33.1933H49.6276ZM39.8074 18.5039H30.8914L39.8074 27.4199V18.5039ZM19.0039 18.5039H27.9199L19.0039 27.4199V18.5039ZM19.0039 39.3086H27.9199L19.0039 30.3926V39.3086ZM30.8916 39.3086H39.8076V30.3926L30.8916 39.3086Z"
      fill="black"
    />
  </svg>
);
