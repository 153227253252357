import { yupResolver } from '@hookform/resolvers/yup';
import { Fragment, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { CheckBox } from '@/components/form/contactForm/CheckBox';
import { Input } from '@/components/form/contactForm/Input';
import { Message } from '@/components/form/contactForm/Message';
import { SuccessModal } from '@/components/form/SuccessModal';
import {
  FormSchemaContact,
  FormSchemaContactType,
} from '@/constants/formSchemas';
import { useDisableScroll } from '@/hooks/useDisableScroll';

export interface FormSectionProps {
  onSubmit: SubmitHandler<FormSchemaContactType>;
  loading: boolean;
  success: boolean;
  setSuccess: () => void;
  setLoading: () => void;
  anchor?: string;
}

export const FormSectionContact = ({
  success,
  loading,
  onSubmit,
  setSuccess,
  setLoading,
  anchor,
}: FormSectionProps) => {
  const { control, handleSubmit, reset } = useForm<FormSchemaContactType>({
    defaultValues: {
      terms: false,
      email: '',
      fullName: '',
      companyName: '',
      message: '',
    },
    mode: 'all',
    resolver: yupResolver(FormSchemaContact),
  });

  useDisableScroll(success);

  useEffect(() => {
    if (success) {
      reset();
      setLoading();
    }
  }, [success]);

  return (
    <Fragment>
      <form id={anchor} onSubmit={handleSubmit(onSubmit)}>
        <div className="-mx-4 flex flex-wrap justify-center">
          <Input
            label="Jméno a příjmení"
            placeholder="Jméno a příjmení"
            name="fullName"
            type="text"
            control={control}
            classNameWrapper="md:w-1/3"
          />
          <Input
            label="Společnost"
            placeholder="Společnost"
            name="companyName"
            type="text"
            control={control}
            className="col-span-2"
            classNameWrapper="md:w-1/3"
          />
          <Input
            label="Email"
            placeholder="Email"
            name="email"
            type="text"
            control={control}
            classNameWrapper="md:w-1/3"
          />
          <Message
            label="Mám zájem o"
            placeholder="Vložte svůj komentář"
            name="message"
            className="col-span-2 md:order-5"
            control={control}
          />
          <CheckBox name="terms" control={control}>
            Souhlasím se shromažďováním a uchováváním mých odeslaných údajů.
          </CheckBox>
          <div className="w-full px-4 sm:w-8/12 lg:w-4/12">
            <div className="text-center">
              {loading ? (
                <div className="text-base rounded-md block w-full bg-primary px-10 py-5 text-center font-medium text-white transition hover:bg-opacity-90">
                  Odesílání ...
                </div>
              ) : (
                <button
                  type="submit"
                  className="text-base rounded-md block w-full bg-primary px-10 py-5 text-center font-medium text-white transition hover:bg-opacity-90"
                >
                  Odeslat
                </button>
              )}
            </div>
          </div>
        </div>
      </form>
      {success && <SuccessModal onClick={setSuccess} />}
    </Fragment>
  );
};
