import clsx from 'clsx';
import { Control, useController } from 'react-hook-form';

import { FormSchemaContactType } from '@/constants/formSchemas';

type Props = {
  label: string;
  placeholder: string;
  name: 'message';
  className?: string;
  classNameWrapper?: string;
  control: Control<FormSchemaContactType>;
};

export const Message = ({
  label,
  placeholder,
  name,
  className,
  classNameWrapper,
  control,
}: Props) => {
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({ control, name });

  return (
    <div className={clsx('relative w-full px-4', classNameWrapper)}>
      <div className="mb-7 lg:mb-9">
        <label className="text-base mb-3 block font-medium text-dark">
          <span>{label}:</span>
        </label>
        <div className={clsx('relative w-full lg:mt-1', className)}>
          <textarea
            rows={4}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            className={clsx(
              'rounded text-base peer block w-full resize-none appearance-none border border-stroke bg-white px-[14px] py-3 leading-relaxed text-body-color outline-none transition-colors duration-300 focus:border-primary focus:outline-none focus-visible:shadow-none motion-reduce:transition-none md:px-[18px] md:py-4',
              value ? 'text-body-color' : 'text-body-color/40',
            )}
            id={name}
          />
          {error && (
            <p className="absolute left-0 top-full pt-2 text-p-small text-red-500">
              {error?.message}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};
