import Link from 'next/link';
import { useState } from 'react';

import Dropdown from '@/components/header/Dropdown';
import DropdownItem from '@/components/header/DropdownItem';
import LinkItem from '@/components/header/LinkItem';
import ListItem from '@/components/header/ListItem';
import Picture from '@/components/Picture/Picture';
import { projectConfig } from '@/constants/projectConfig';

const Header = () => {
  const [open, setOpen] = useState(false);

  return (
    <header className="relative z-[2] flex w-full items-center bg-white">
      <div className="container mx-auto">
        <div className="relative -mx-4 flex items-center justify-between">
          <div className="w-60 max-w-full px-4">
            <Link href="/" className="block w-full py-5">
              <Picture
                imgSrc={`${projectConfig.cloudinaryProjectName}/collapo-logo`}
                alt="Collapo logo"
                classNameImage="lib-h-[52px] lib-w-auto xs:lib-h-[80px] md:lib-h-[115px]"
                classNameWrapper="lib-mx-[22px]"
                width={160}
                height={60}
                enabledLazyLoading={false}
              />
            </Link>
          </div>
          <div className="flex w-full items-center justify-between px-4">
            <div>
              <button
                onClick={() => setOpen(!open)}
                className={` ${
                  open && 'navbarTogglerActive'
                } rounded-lg absolute right-4 top-1/2 block -translate-y-1/2 px-3 py-[6px] ring-primary focus:ring-2 lg:hidden`}
              >
                <span className="relative my-[6px] block h-[2px] w-[30px] bg-body-color"></span>
                <span className="relative my-[6px] block h-[2px] w-[30px] bg-body-color"></span>
                <span className="relative my-[6px] block h-[2px] w-[30px] bg-body-color"></span>
              </button>
              <nav
                className={`rounded-lg absolute right-4 top-full z-50 w-full max-w-[250px] bg-white py-5 shadow lg:static lg:block lg:w-full lg:max-w-full lg:bg-transparent lg:px-6 lg:py-0 lg:shadow-none  ${
                  !open && 'hidden'
                } `}
              >
                <ul className="block lg:flex">
                  <ListItem>
                    <LinkItem dropdown navLink="/">
                      Produkty a služby
                    </LinkItem>
                    <Dropdown>
                      <DropdownItem dropdownLink="#" dropdownText="LAN/WAN" />
                      <DropdownItem
                        dropdownLink="#"
                        dropdownText="Kybernetická bezpečnost"
                      />
                      <DropdownItem
                        dropdownLink="#"
                        dropdownText="Audio & Video Realizace "
                      />
                      <DropdownItem
                        dropdownLink="#"
                        dropdownText="Cloudová řešení"
                      />
                      <DropdownItem dropdownLink="#" dropdownText="Služby" />
                    </Dropdown>
                  </ListItem>
                  <ListItem>
                    <LinkItem navLink="/#">Reference</LinkItem>
                  </ListItem>
                  <ListItem>
                    <LinkItem dropdown navLink="/">
                      Výrobci a Partneři
                    </LinkItem>
                    <Dropdown>
                      <DropdownItem dropdownLink="#" dropdownText="Výrobci" />
                      <DropdownItem
                        dropdownLink="#"
                        dropdownText="Obchodní partneři"
                      />
                    </Dropdown>
                  </ListItem>
                  <ListItem>
                    <LinkItem dropdown navLink="/">
                      Společnost
                    </LinkItem>
                    <Dropdown>
                      <DropdownItem dropdownLink="#" dropdownText="O nás" />
                      <DropdownItem
                        dropdownLink="#"
                        dropdownText="Vedení společnosti"
                      />
                    </Dropdown>
                  </ListItem>
                </ul>
              </nav>
            </div>
            <div className="hidden justify-end pr-16 sm:flex lg:pr-0">
              <Link
                href="/podpora"
                className="rounded-md text-base bg-primary px-6 py-2.5 font-medium text-white hover:bg-primary/90"
              >
                Podpora
              </Link>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
